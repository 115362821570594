<template>
    <div class="tool-container">
        <div class="login-box">
            <div class="header-btn">
                <van-icon name="setting-o" size="20px" color="#fff" @click="$router.push('/settings')"/>
            </div>
            <div class="user-box">
                <div class="user-info">
                    <avater width="50px" height="50px" :avaterImg="userInfo.avatar || defaultImg"></avater>
                    <div class="user-name">{{ userInfo.nickname }}</div>
                    <div class="eidt-user">
                        <van-icon name="edit" @click="$router.push('/EditUserInfo')"/>
                        <span class="my-page" @click="$router.push('/homepage')">我的主页</span>
                        <van-icon name="arrow" />
                    </div>
                </div>
                <div class="user-tab">
                    <van-grid :border="false" column-num="3">
                        <van-grid-item @click="$router.push('/homepage')">
                            <template #default>
                                <div class="count">{{userInfo.send_count }}</div>
                                <div class="text">创作</div>
                            </template>
                        </van-grid-item>
                        <van-grid-item @click="$router.push({path:'/follow',query:{user_id:userInfo.id},})">
                            <template #default>
                                <div class="count">{{userInfo.follow_count}}</div>
                                <div class="text">关注</div>
                            </template>
                        </van-grid-item>
                        <van-grid-item @click="$router.push('/fans')">
                            <template #default>
                                <div class="count">{{userInfo.fans_count }}</div>
                                <div class="text">粉丝</div>
                            </template>
                        </van-grid-item>
                    </van-grid>
                </div>
            </div>
            <div class="record-box">
                <van-grid :border="false" :column-num="3">
                    <van-grid-item text="收藏" @click="$router.push({path: '/userRecord',query:{actives:0},})">
                        <template #icon>
                            <collectsActive class="icons-img"></collectsActive>
                        </template>
                    </van-grid-item>
                    <van-grid-item text="我的评论" @click="$router.push({path: '/userRecord',query:{actives:1},})">
                        <template #icon>
                            <replysActive class="icons-img"></replysActive>
                        </template>
                    </van-grid-item>
                    <van-grid-item text="我赞过的" @click="$router.push({path: '/userRecord',query:{actives:2},})">
                        <template #icon>
                            <likesActive class="icons-img"></likesActive>
                        </template>
                    </van-grid-item>
                </van-grid>
            </div>
            <div class="other-service">
                <div class="service-title">
                    其他服务
                </div>
                <div class="service-content">
                    <van-cell-group :border="false">
                        <van-cell title="草稿箱" is-link :border="false" :clickable="true">
                            <template #icon>
                                <draft class="draft"></draft>
                            </template>
                        </van-cell>
                        <van-cell title="设置" icon="setting-o" is-link :border="false" :clickable="true" @click="$router.push('/settings')" />
                    </van-cell-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// 头像
import avater from '@/components/Mobile/avater'
import { collectsActive, replysActive, likesActive, draft } from '../../../core/icon';
export default {
    name: 'Tool',
    components: {
        likesActive,
        replysActive,
        collectsActive,
        draft,
        avater,
    },
    computed: {
        ...mapState(['token', 'userInfo'])
    },
    data() {
        return {
            // 默认头像
            defaultImg:require('@/assets/images/userLogo.jpg'),
        };
    },

    activated() {
        this.loadUserInfo();
    },

    methods: {
        loadUserInfo()
    {
        this.$store.dispatch('getUserInfo');
    }
    },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>